import Header from '../components/header';
import Contact from '../components/contact';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import ogImage from '../assets/img/og-image-home.png';

function CarrmProgram() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Educator Training | Music Therapy Brisbane"
        description="2.5-hour training for educators on implementing trauma-informed early childhood music protocols to support self-regulation and relational capacity in young children."
        keywords="educator training, trauma-informed, early childhood, music therapy, self-regulation"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/services/educator-training"
      />
      <Header></Header>
      <section className="hero-services-detail pb-15 pt-15 lg:pb-15 lg:pt-15 xl:pb-15 2xl:pt-15">
        <div className="container mx-auto px-0 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            {/* Hero content can be added here if needed */}
          </div>
        </div>
      </section>
      <section className="about-home bg-[#f1f7fd] py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
            <div className="mx-auto text-left"> 
            <h1 className="font-bold mb-2 text-5xl text-red uppercase">The CARRM program</h1>
            {/* <h3 className="font-bold mb-4 text-3xl text-green lg:text-4xl pt-10">Educator Training</h3> */}
            <p className="my-6 text-base lg:text-3xl text-left text-green"><strong>C</strong>onnection<br/><strong>A</strong>nd <br/><strong>R</strong>egulation through<br/><strong>R</strong>hythmic play and<br/><strong>M</strong>usic</p>
            
            <div className="prose max-w-none mb-10">
              <p className="mb-6">In this training, music therapist Sarah Dunston presents a protocol based on Dr Bruce Perry's groundbreaking Neurosequential Model of Therapeutics. It comprises a 2.5 hour training to learn how to implement a trauma informed early childhood adapted music therapy protocol to aide in developing self-regulation and relational capacity in young children engaged in early education. No prior musical experience required. In person or on-line.</p>
              
              <p className="mb-6 font-bold italic">At the end of this program, you will walk away with a done for you system and a toolbox of resources and activities you can pick and choose for use in any early years setting straight away.</p>
              
              <h4 className="font-semibold text-xl mb-4">The training covers:</h4>
              <ol className="list-decimal pl-6 mb-8 space-y-2">
                <li>Current research and theory on complex trauma and child brain development.</li>
                <li>An exploration of the Neurosequential Model of Therapeutics and its application to music therapy.</li>
                <li>Examples from practice in an early intervention preschool for children impacted by abuse and neglect.</li>
                <li>Practical information, activities and resources to embed the protocol in any early learning space straight away.</li>
              </ol>
              
              {/* <p className="mb-4">Our statistics show* after ten weeks of the teachers consistently using the activities presented in the classroom for at least 30 minutes every school day, you should see a significant reduction in challenging externalising behaviours, an increase in pro social behaviours and more engagement in learning.</p>
              <p className="italic text-sm">*Based on five years utilising the program in a specialised kindergarten for children at risk.</p> */}
            </div>
            
            
            
            </div>
        </div>             
      </section>
      <Contact></Contact>            
      <Footer></Footer>      
    </>
  );
}

export default CarrmProgram;