import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import ogImage from '../assets/img/og-image-home.png';

function NotFound() {
  useEffect(() => {
    // This will set the status code to 404
    // It works because we're using a static file server that supports this header
    document.title = "404: Not Found";
    const meta = document.createElement('meta');
    meta.httpEquiv = "Status";
    meta.content = "404";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, []);

  return (
    <>
      <SEO 
        title="404: Page Not Found | Melodica Music Therapy"
        description="The page you are looking for cannot be found."
        keywords="404, not found, error"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/404"
      />
      <Header />
      <section className="py-20 lg:py-28">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h1 className="font-bold mb-4 text-4xl text-red-500 lg:text-6xl">404</h1>
            <h2 className="font-bold mb-4 text-2xl text-gray-800 lg:text-4xl">Page Not Found</h2>
            <p className="mb-8 text-gray-600">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <Link to="/" className="btn btn-yellow font-bold inline-block px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">
              Return to Homepage
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NotFound;