import Header from '../../components/header';
import Contact from '../../components/contact';
import Footer from '../../components/footer';
import SEO from '../../components/SEO';
import cardOneMobile from '../../assets/img/service-card-1-mobile.png';
import cardOneDesktop from '../../assets/img/service-card-1-desktop.png';
import cardTwoMobile from '../../assets/img/service-card-2-mobile.png';
import cardTwoDesktop from '../../assets/img/service-card-2-desktop.png';
import cardThreeMobile from '../../assets/img/service-card-3-mobile.png';
import cardThreeDesktop from '../../assets/img/service-card-3-desktop.png';
import cardFourMobile from '../../assets/img/service-card-4-mobile.png';
import cardFourDesktop from '../../assets/img/service-card-4-desktop.png';
import cardFiveMobile from '../../assets/img/service-card-5-mobile.png';
import cardFiveDesktop from '../../assets/img/service-card-5-desktop.png';
import ogImage from '../../assets/img/og-image-home.png';

function ServicesAgedCare() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Aged Care Services | Music Therapy Brisbane"
        description="Trauma-informed music therapy for aged care, including group sessions for dementia, individual therapy, and choirs. Promoting cognitive maintenance, emotional regulation, and anxiety reduction."
        keywords="aged care, music therapy, dementia, trauma-informed, cognitive maintenance, emotional regulation, anxiety reduction, group therapy, individual therapy, choir"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/services/aged-care"
        />
      <Header></Header>
      { /* HERO SERVICES */ }
      <section className="hero-services-detail pb-15 pt-15 lg:pb-15 lg:pt-15 xl:pb-15 2xl:pt-15">
        <div className="container mx-auto px-0 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            {/* <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">SERVICES</h2>
            <h1 className="font-bold mb-3 text-4xl text-white lg:mb-6 lg:text-6xl">Melodica Music Therapy can provide services to a wide range of clientele</h1> */}
            {/* <p className="mb-6 text-base text-white lg:text-xl">A fun and engaging music therapy program designed to develop core skills such as language, motor skills and emotional regulation.</p> */}
            {/* <a href="#" className="btn btn-shadow btn-yellow font-bold inline-block px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">Get Startd Now</a> */}
          </div>
        </div>
      </section>
      <section className="about-home bg-[#f1f7fd] py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
            <div className="mx-auto text-left"> 
            <h1 className="font-bold mb-2 text-5xl text-red uppercase">Aged Care Services</h1>
            <h3 className="font-bold mb-4 text-3xl text-green lg:text-4xl pt-10">Trauma informed music therapy for individuals and groups to promote cognitive maintenance, emotional regulation and anxiety reduction. </h3>
            <p className="mb-3 roboto text-base">
                Specific programs include:
            </p>
            <ul className="list-disc pl-5 mb-3 roboto text-base max-w-4xl">
                <li className='mb-3'>
                <strong>Group music therapy for Dementia and Aged Care:</strong> Trauma informed music therapy program designed to encourage group participation, memory retrieval, cognitive maintenance and emotional regulation. Can choose from guitar and percussion play sing along, piano for relaxation, musical bingo and Guess That Song (Karaoke).
                </li>
                <li className='mb-3'>
                <strong>Individual Music Therapy for Dementia:</strong> Trauma informed one-on-one music therapy tailored to the individual needs of the client. Benefits include anxiety reduction, orientation to present, plus increased sense of wellness and better mood and sleep.
                </li>
                <li className='mb-3'>
                <strong>Aged Care or Dementia Choir:</strong> Singing together provides many benefits including anxiety reduction and an increased sense of well-being, and connection to others. Repertoire will be based on client choice where possible.
                </li>
            </ul>
            </div>
                        
        </div>             
        </section>
      { /* CONTACT */ }      
      <Contact></Contact>            
      { /* MAP */ }      
      {/* <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>             */}
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default ServicesAgedCare;