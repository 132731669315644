import Header from '../../components/header';
import Contact from '../../components/contact';
import Footer from '../../components/footer';
import SEO from '../../components/SEO';
import ogImage from '../../assets/img/og-image-home.png';

function ServicesDrummingTherapy() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Drumming Therapy | Music Therapy Brisbane"
        description="Discover the benefits of drumming therapy - a powerful tool in music therapy for relaxation, brain synchronization, and overall well-being."
        keywords="drumming therapy, music therapy, brain synchronization, stress reduction, immunity boost"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/services/drumming-therapy"
      />
      <Header></Header>
      <section className="hero-services-detail pb-15 pt-15 lg:pb-15 lg:pt-15 xl:pb-15 2xl:pt-15">
        <div className="container mx-auto px-0 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            {/* Hero content can be added here if needed */}
          </div>
        </div>
      </section>
      <section className="about-home bg-[#f1f7fd] py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
            <div className="mx-auto text-left"> 
            <h1 className="font-bold mb-2 text-5xl text-red uppercase">Drumming Therapy</h1>
            <h3 className="font-bold mb-4 text-3xl text-green lg:text-4xl pt-10">Drumming is a commonly used tool during Music Therapy to create a space where the body and mind are allowed to go into a state of flow.</h3>
            <p className="mb-6 roboto text-base">
                It activates both sides of the brain and can help achieve a situation where both halves of the brain are active and brain waves are synchronized, leading to feeling both energised and relaxed (Friedman, R.L, 2000).
            </p>
            <h4 className="font-bold mb-3 text-2xl text-green">Why do drumming in Music Therapy?</h4>
            <ol className="list-decimal pl-5 mb-6 roboto text-base max-w-4xl">
                <li className="mb-3">
                    <strong>It's for everyone:</strong> Drumming does not require advanced physical abilities, specialised talents or even being able to read music. Drumming, even a simple pattern, offers benefits to a huge range of people.
                </li>
                <li className="mb-3">
                    <strong>It's calming:</strong> Drumming works to calm and regulate the primitive part of our brains which cause the 'flight or fight' response and becomes reactive when we are stressed, anxious or have experienced trauma.
                </li>
                <li className="mb-3">
                    <strong>It increases immunity and helps fight disease in the body:</strong> Studies have found that drumming can boost your immunity. Reducing stress in the body with some drumming can help get the system back in balance.
                </li>
            </ol>
            </div>
        </div>             
      </section>
      <Contact></Contact>            
      <Footer></Footer>      
    </>
  );
}

export default ServicesDrummingTherapy;