import Header from '../components/header';
import Contact from '../components/contact';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import cardOne from '../assets/img/uplift-card-1.png';
import cardTwo from '../assets/img/uplift-card-2.png';
import cardThree from '../assets/img/uplift-card-3.png';
import cardFour from '../assets/img/uplift-card-4.png';
import ogImage from '../assets/img/og-image-home.png';


function Uplift() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Kindy Uplift | Music Therapy Brisbane"
        description="Trauma Informed Music Therapy and Training for bigs, littles and in-betweens in Brisbane, Australia."
        keywords="music therapy, trauma informed, Brisbane, children, development"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au"
      />
      <Header></Header>
      { /* HERO UPLIFT */ }
      <section className="hero-uplift pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
          <div className="mx-auto text-center max-w-[1100px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">Uplift</h2>
            <h1 className="font-bold mb-3 text-4xl text-white uppercase lg:mb-6 lg:text-6xl">KINDY UPLIFT</h1>
            <p className="mb-6 text-base text-white lg:text-xl">A half day taining workshop to provide teachers with a trauma informed music based protocol</p>
            {/* <a href="#" className="btn btn-shadow btn-yellow font-bold inline-block px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">Get Startd Now</a> */}
          </div>
        </div>
      </section>
      <section className="py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
          <div className="max-w-[920px] mx-auto text-center"> 
            <h2 className="font-bold mb-2 text-2xl text-red uppercase">Uplift</h2>
            <h3 className="font-bold mb-4 text-3xl text-green uppercase lg:text-5xl">Kindy UPLIFT</h3>
            {/* <p className="mb-3 roboto text-base">Sarah Dunston is a Brisbane (Australia) based Registered Music Therapist (RMT) specialising in community work. She has worked in many different contexts across the life span including residential aged care, community mental health, adult disability, schools (Flexi, special and mainstream) and early childhood (capacity building and early intervention).</p>  */}
          </div>
          <div className="gap-20 grid mt-10 lg:gap-16 lg:mt-16">
            <div className="card-uplift lg:flex">
              <div className="relative lg:flex-shrink-0">
                <img alt="" src={cardOne} className="h-auto w-full" width={450} height={450} />
              </div>
              <div className="p-4 lg:flex lg:flex-col lg:justify-center lg:pl-6 lg:py-2">
                <h3 className="font-bold mb-4 text-3xl text-center text-red uppercase lg:text-left">Professional Development for Teachers</h3> 
                <p className="line-clamp-4 mb-4 roboto text-base text-black text-center lg:line-clamp-6 lg:mb-6 lg:text-left">In this half day training, I will show up to 24 educators all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program.</p>
                <div className="text-center lg:text-left"><a href="/contact" className="border-2 border-black btn btn-yellow font-bold inline-flex items-center px-6 py-3 rounded-lg text-xl uppercase hover:bg-yellow-500 lg:px-8 lg:py-4">ENQUIRE NOW <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-3"> 
                      <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" /> 
                      <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" /> 
                    </svg></a>
                </div>
              </div>                         
            </div>
            <div className="card-uplift lg:flex">
              <div className="relative lg:flex-shrink-0">
                <img alt="" src={cardTwo} className="h-auto w-full" width={450} height={450} />
              </div>
              <div className="p-4 lg:flex lg:flex-col lg:justify-center lg:pl-6 lg:py-2">
                <h3 className="font-bold mb-4 text-3xl text-center text-red uppercase lg:text-left">FAMILY INFO SESSION</h3> 
                <p className="line-clamp-4 mb-4 roboto text-base text-black text-center lg:line-clamp-6 lg:mb-6 lg:text-left">Discussion with families on how complex trauma impacts neural development and behaviour of young children + strategies they can use in the home to help support and regulate their loved ones using music.</p>
                <div className="text-center lg:text-left"><a href="/contact" className="border-2 border-black btn btn-yellow font-bold inline-flex items-center px-6 py-3 rounded-lg text-xl uppercase hover:bg-yellow-500 lg:px-8 lg:py-4">ENQUIRE NOW <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-3"> 
                      <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" /> 
                      <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" /> 
                    </svg></a>
                </div>
              </div>                         
            </div>
            <div className="card-uplift lg:flex">
              <div className="relative lg:flex-shrink-0">
                <img alt="" src={cardThree} className="h-auto w-full" width={450} height={450} />
              </div>
              <div className="p-4 lg:flex lg:flex-col lg:justify-center lg:pl-6 lg:py-2">
                <h3 className="font-bold mb-4 text-3xl text-center text-red uppercase lg:text-left">RESOURCE DEVELOPMENT</h3> 
                <p className="line-clamp-4 mb-4 roboto text-base text-black text-center lg:line-clamp-6 lg:mb-6 lg:text-left">Review of existing resources + curated purchasing list of additional resources needed.</p>
                <div className="text-center lg:text-left"><a href="/contact" className="border-2 border-black btn btn-yellow font-bold inline-flex items-center px-6 py-3 rounded-lg text-xl uppercase hover:bg-yellow-500 lg:px-8 lg:py-4">ENQUIRE NOW <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-3"> 
                      <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" /> 
                      <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" /> 
                    </svg></a>
                </div>
              </div>                         
            </div>
            <div className="card-uplift lg:flex">
              <div className="relative lg:flex-shrink-0">
                <img alt="" src={cardFour} className="h-auto w-full" width={450} height={450} />
              </div>
              <div className="p-4 lg:flex lg:flex-col lg:justify-center lg:pl-6 lg:py-2">
                <h3 className="font-bold mb-4 text-3xl text-center text-red uppercase lg:text-left">CUSTOMISED COACHING</h3> 
                <p className="line-clamp-4 mb-4 roboto text-base text-black text-center lg:line-clamp-6 lg:mb-6 lg:text-left">Guided development of teaching/learning experiences for children and staff organisational review and action plan monthly check-in re wins and challenges and how to move forward.</p>
                <div className="text-center lg:text-left"><a href="/contact" className="border-2 border-black btn btn-yellow font-bold inline-flex items-center px-6 py-3 rounded-lg text-xl uppercase hover:bg-yellow-500 lg:px-8 lg:py-4">ENQUIRE NOW <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-3"> 
                      <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" /> 
                      <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" /> 
                    </svg></a>
                </div>
              </div>                         
            </div>                     
          </div>                 
        </div>             
      </section>      
      { /* CONTACT */ }      
      <Contact></Contact>            
      { /* MAP */ }      
      {/* <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>             */}
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Uplift;