import Header from '../components/header';
import Contact from '../components/contact';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import course from '../assets/img/courses.png';
import ogImage from '../assets/img/og-image-home.png';

function Courses() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Courses | Music Therapy Brisbane"
        description="Trauma Informed Music Therapy and Training for bigs, littles and in-betweens in Brisbane, Australia."
        keywords="music therapy, trauma informed, Brisbane, children, development"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au"
      />
      <Header></Header>
      { /* HERO COURSES */ }
      <section className="hero-courses pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
          <div className="mx-auto text-center max-w-[800px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">Courses</h2>
            <h1 className="font-bold mb-3 text-4xl text-white uppercase lg:mb-6 lg:text-6xl">THE NEUROSEQUENTIAL MODEL OF THERAPEUTICS</h1>
            <p className="mb-6 text-base text-white lg:text-xl">A new direction for trauma informed music therapy</p>
            <a href="https://musictherapyonline.org/p/the-neurosequential-model-of-therapeutics" target="_blank" className="btn btn-shadow btn-yellow font-bold inline-block px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">Get Started Now</a>
          </div>
        </div>
      </section>
      <section className="py-20 lg:py-28"> 
          <div className="container mx-auto px-4"> 
            <div className="gap-6 grid lg:gap-12 lg:grid-cols-2"> 
              <div> 
                <h2 className="font-bold mb-2 text-2xl text-center text-red uppercase lg:hidden lg:text-left">Courses</h2>
                <h3 className="font-bold mb-6 text-3xl text-center text-green uppercase lg:hidden lg:text-5xl lg:text-left">The Neurosequential Model of Therapeutics</h3>
                <img alt="" src={course} className="block w-full" />
              </div>                     
              <div className="lg:pt-10"> 
                <h2 className="font-bold hidden mb-2 text-2xl text-center text-red uppercase lg:block lg:text-left">Courses</h2>
                <h3 className="font-bold hidden mb-4 text-3xl text-center text-green uppercase lg:block lg:text-5xl lg:text-left">The Neurosequential Model of Therapeutics</h3>
                <p className="lg:text-left mb-3 roboto text-base text-center">Are you working with children who have experienced trauma?</p>
                <p className="lg:text-left mb-3 roboto text-base text-center">Are you up to date with current research and practice in this area?</p>
                <p className="lg:text-left mb-3 roboto text-base text-center">Would you like to know what interventions work well to help children regulate, relate to others, and thrive in their development?</p>
                <p className="lg:text-left mb-3 roboto text-base text-center">Then you’re in the right place!</p>
                <p className="lg:text-left mb-3 roboto text-base text-center">This online tutorial from music therapist Sarah Dunston presents Dr Bruce Perry’s groundbreaking Neurosequential model of therapeutics and shows how it can be used in music therapy.</p>
                <p className="roboto text-base text-center lg:text-left">Learn how to design a music therapy program which will calm a child’s nervous system, increase their relational ability, and help them overcome the dissociative response.</p>
                <p className="mb-8 roboto text-base text-center lg:text-left">Ms Dunston discusses why taking a trauma informed approach is vital when working with young children who have experienced complex trauma. She presents the 5 key elements of a trauma informed music therapy protocol which uses rhythmic play, cross-lateral movement, and proprioceptive stimulation to support each child to calm their nervous system.</p> 
                <div className="text-center lg:text-left"><a href="https://musictherapyonline.org/p/the-neurosequential-model-of-therapeutics" target="_blank" className="btn btn-shadow btn-yellow font-bold inline-flex items-center px-20 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:py-4">ENROLL NOW</a>
                </div>
              </div>                     
            </div>                 
          </div>             
        </section>
        {/* <section className="bg-[#f1f7fd] py-20 lg:py-28"> 
          <div className="container mx-auto px-4"> 
            <div className="max-w-[920px] mx-auto text-center"> 
              <h3 className="font-bold mb-1 text-3xl text-green uppercase lg:text-5xl">Selective Mutism</h3>
              <h2 className="mb-3 text-3xl uppercase lg:font-medium">Does music therapy hold the words</h2>
              <p className="mb-3 roboto text-base lg:font-medium">The above method has been presented at various conferences and events including the Australian Music Therapy Conference, The Truama Aware Schooling Conference and more recently was presented at the Music Therapy for Families Symposium in Vienna, Austria by UK Music Therapist Jo Turner as a case study in working with children with Selective Mutism.</p> 
            </div>                 
            <div className="gap-6 grid mt-8 sm:grid-cols-2 lg:grid-cols-3 lg:mt-12 xl:grid-cols-4 2xl:grid-cols-5"> 
              <div> 
                <div className="bg-[#328dad] card-courses px-4 py-8 rounded-xl lg:h-full lg:py-12">
                  <h2 className="font-bold mb-4 text-3xl text-center text-white uppercase">THE THEORY</h2>
                  <p className="roboto text-base text-center text-white">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone programIn this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program..In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program.</p> 
                </div>                         
              </div>
              <div> 
                <div className="bg-[#328dad] card-courses px-4 py-8 rounded-xl lg:h-full lg:py-12">
                  <h2 className="font-bold mb-4 text-3xl text-center text-white uppercase">THE FAMILY</h2>
                  <p className="roboto text-base text-center text-white">Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills. Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.</p> 
                </div>                         
              </div>
              <div> 
                <div className="bg-[#328dad] card-courses px-4 py-8 rounded-xl lg:h-full lg:py-12">
                  <h2 className="font-bold mb-4 text-3xl text-center text-white uppercase">THE MODEL</h2>
                  <p className="roboto text-base text-center text-white">Mindful Mats is a multi-modal program designed to run in line with the early childhood learning framework utilizing  music, art, sound and movement. Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.</p> 
                </div>                         
              </div>
              <div> 
                <div className="bg-[#328dad] card-courses px-4 py-8 rounded-xl lg:h-full lg:py-12">
                  <h2 className="font-bold mb-4 text-3xl text-center text-white uppercase">THE JOURNEY</h2>
                  <p className="roboto text-base text-center text-white">Individual and small group music therapy sessions tailored to the needs of individuals for clients with NDIS self managed and provider managed clientsTrauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social .</p> 
                </div>                         
              </div>
              <div> 
                <div className="bg-[#328dad] card-courses px-4 py-8 rounded-xl lg:h-full lg:py-12">
                  <h2 className="font-bold mb-4 text-3xl text-center text-white uppercase">THE CONCLUSION</h2>
                  <p className="roboto text-base text-center text-white">A fun and engaging music therapy program designed to develop core skills such as language, motor skills and emotional regulation. Will include activities..Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.Trauma Informed group music therapy program designed to develop school readiness skills including language, emotional and attentional regulation, motor skills and social skills.Trauma Informed.</p> 
                </div>                         
              </div>                     
            </div>                 
          </div>             
        </section>       */}
      { /* CONTACT */ }      
      <Contact></Contact>            
      { /* MAP */ }      
      {/* <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>             */}
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Courses;