import { NavLink, Outlet, useLocation } from 'react-router-dom';
import footer from '../assets/img/logo-footer.png';
function Footer() {
  const location = useLocation();
  const activeKey = location.pathname === '/' ? '/home' : location.pathname;
  return (
      <>
      <footer className="footer pt-20 lg:pt-36 2xl:pt-44"> 
        <div className="container mx-auto px-4 relative"> 
          <div className="lg:pb-8">
            <div className="gap-6 grid md:grid-cols-2 lg:grid-cols-4"> 
              <div> <a href="#" className="inline-block"> <img src={footer} width={254} height={75} className="h-auto w-40 md:w-48 2xl:w-64" /></a> 
              </div>                         
              <div>
                {/* <h2 className="font-semibold mb-6 text-white text-xl uppercase">Company</h2> */}
                <div> 
                  <div> 
                    <ul className="font-bold roboto text-xs"> 
                      <li className="mb-2"> <NavLink to="/" className="text-opacity-55 text-white hover:text-yellow-400">Home</NavLink> 
                      </li>
                      <li className="mb-2"> <NavLink to="/about" className="text-opacity-55 text-white hover:text-yellow-400">About</NavLink> 
                      </li>                                         
                      <li className="mb-2"> <NavLink to="/services" className="text-opacity-55 text-white hover:text-yellow-400">Services</NavLink> 
                      </li>                                         
                      <li className="mb-2"> <NavLink to="/courses" className="text-opacity-55 text-white hover:text-yellow-400">Courses</NavLink> 
                      </li>                                         
                      <li className="mb-2"> <NavLink to="/uplift" className="text-opacity-55 text-white hover:text-yellow-400">Uplift</NavLink> 
                      </li>
                      {/* <li className="mb-2"> <NavLink to="#" className="text-opacity-55 text-white hover:text-yellow-400">Gallery</NavLink> 
                      </li>
                      <li className="mb-2"> <NavLink to="#" className="text-opacity-55 text-white hover:text-yellow-400">News and Blogs</NavLink> 
                      </li> */}
                      <li className="mb-2"> <NavLink to="/contact" className="text-opacity-55 text-white hover:text-yellow-400">Contact</NavLink> 
                      </li>                                         
                    </ul>
                    <Outlet />                                     
                  </div>                                 
                </div>                             
              </div>
              <div>
                
                <div> 
                  <div className="flex items-center">
                    <div className="mr-4">
                      <i className="fa-lg fa-map-marker-alt fas py-3 rounded-bl text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold text-base text-white uppercase">Address</h5>
                      <p className="font-semibold roboto text-opacity-55 text-white text-xs">Brisbane, QLD, Australia, 4030</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4">
                      <i className="fa-lg fa-phone-alt fas text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold text-base text-white uppercase">MOBILE</h5>
                      <p className="font-semibold roboto text-lg text-yellow-400"><a href="tel:+61452391605">0452 391 605</a></p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4">
                      <i className="fa-envelope fa-lg fas text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold text-base text-white uppercase">EMAIL</h5>
                      <p className="font-semibold roboto text-opacity-55 text-white text-xs">info@melodica.com.au</p>
                    </div>
                  </div>                                 
                </div>                             
              </div>                         
              {/* <div> 
                <h2 className="font-semibold mb-4 text-white text-xl uppercase">SUBSCRIBE NEWSLETTER</h2> 
                <form> 
                  <div className="grid"> 
                    <input className="appearance-none font-semibold outline-none px-4 py-4 roboto rounded-md text-sm" placeholder="Enter your email address" type="email" /> 
                  </div>
                  <div className="grid mt-3"> 
                    <button className="btn btn-yellow font-bold inline-block px-6 py-3 roboto rounded-md text-center hover:bg-yellow-500 lg:px-10 lg:py-4" type="submit">Subscribe Now</button>                                     
                  </div>                                 
                </form>                             
              </div>                          */}
            </div>
          </div>
        </div>
        <hr className="border-opacity-5 border-white mb-4" />
        <div className="container mx-auto px-4 relative"> 
          <div className="pb-8 pt-4 text-sm"> 
            <div className="flex flex-col flex-wrap items-center lg:flex-row lg:justify-between"> 
              <div> 
                <p className="roboto text-sm text-white"><a href="#" className="font-bold hover:text-yellow-400">melodica.com.au</a>&nbsp;© 2024</p> 
              </div>
              <div className="mt-6 lg:mt-0"> 
                <a href="https://www.facebook.com/p/Melodica-Music-Therapy-100063693664516/" className="group inline-block"><span className="fa-lg fa-stack"><i className="fa-circle fa-stack-2x fas text-opacity-30 text-white group-hover:text-opacity-40" /><i className="fa-facebook-f fa-inverse fa-sm fa-stack-1x fab" /></span></a>
                {/* <a href="#" className="group inline-block"><span className="fa-lg fa-stack"><i className="fa-circle fa-stack-2x fas text-opacity-30 text-white group-hover:text-opacity-40" /><i className="fa-inverse fa-sm fa-stack-1x fa-youtube fab" /></span></a> */}
                <a href="https://www.instagram.com/melodica_music_therapy" className="group inline-block"><span className="fa-lg fa-stack"><i className="fa-circle fa-stack-2x fas text-opacity-30 text-white group-hover:text-opacity-40" /><i className="fa-instagram fa-inverse fa-sm fa-stack-1x fab" /></span></a>
              </div>                         
            </div>                     
          </div>                 
        </div>             
      </footer>        
      </>
  );
}
  
export default Footer;