import Header from '../../components/header';
import Contact from '../../components/contact';
import Footer from '../../components/footer';
import SEO from '../../components/SEO';
import ogImage from '../../assets/img/og-image-home.png';

function ServicesEducatorTraining() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Educator Training | Music Therapy Brisbane"
        description="2.5-hour training for educators on implementing trauma-informed early childhood music protocols to support self-regulation and relational capacity in young children."
        keywords="educator training, trauma-informed, early childhood, music therapy, self-regulation"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/services/educator-training"
      />
      <Header></Header>
      <section className="hero-services-detail pb-15 pt-15 lg:pb-15 lg:pt-15 xl:pb-15 2xl:pt-15">
        <div className="container mx-auto px-0 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            {/* Hero content can be added here if needed */}
          </div>
        </div>
      </section>
      <section className="about-home bg-[#f1f7fd] py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
            <div className="mx-auto text-left"> 
            <h1 className="font-bold mb-2 text-5xl text-red uppercase">Educator Training</h1>
            <h3 className="font-bold mb-4 text-3xl text-green lg:text-4xl pt-10">Trauma-informed early childhood music protocol training for educators</h3>
            <p className="mb-6 roboto text-base">
                This 2.5-hour training is designed for educators to learn how to implement a trauma-informed early childhood music protocol. The protocol aids in developing self-regulation and relational capacity in young children (aged 3-5 years). The training is available online or in-person, and no prior musical experience is required.
            </p>
            <p className="mb-6 roboto text-base">
                The training discusses why taking a trauma-informed approach is vital when working with young children who have experienced adversity or complex trauma. It presents the 5 key elements of a trauma-informed music therapy protocol (adapted for early childhood contexts) which uses rhythmic play, cross-lateral movement, and proprioceptive stimulation to support each child in calming their nervous system.
            </p>
            <h4 className="font-bold mb-3 text-2xl text-green">The workshop covers:</h4>
            <ol className="list-decimal pl-5 mb-6 roboto text-base max-w-4xl">
                <li className="mb-3">Current research and theory on complex trauma and child brain development.</li>
                <li className="mb-3">An exploration of the Neurosequential Model of Therapeutics and its application to music therapy.</li>
                <li className="mb-3">Examples from practice using a trauma-informed music therapy protocol.</li>
                <li className="mb-3">Discussion and information on how the protocol can be applied to the early childhood context.</li>
            </ol>
            <p className="mb-6 roboto text-base max-w-4xl">
                This protocol has been presented at various events such as the Australian Music Therapy Conference, The Trauma Aware Schooling Conference, and more recently the Music Therapy and Family Symposium in Vienna, Austria.
            </p>
            </div>
        </div>             
      </section>
      <Contact></Contact>            
      <Footer></Footer>      
    </>
  );
}

export default ServicesEducatorTraining;