import Header from '../../components/header';
import Contact from '../../components/contact';
import Footer from '../../components/footer';
import SEO from '../../components/SEO';
import ogImage from '../../assets/img/og-image-home.png';

function ServicesIndividualMusicTherapy() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Individual Music Therapy | Music Therapy Brisbane"
        description="Tailored music therapy sessions to meet specific needs and NDIS plan goals, improving mental health and wellbeing through creative expression."
        keywords="individual music therapy, NDIS, mental health, communication, emotional regulation, motor skills"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au/services/individual-music-therapy"
      />
      <Header></Header>
      <section className="hero-services-detail pb-15 pt-15 lg:pb-15 lg:pt-15 xl:pb-15 2xl:pt-15">
        <div className="container mx-auto px-0 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            {/* Hero content can be added here if needed */}
          </div>
        </div>
      </section>
      <section className="about-home bg-[#f1f7fd] py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
            <div className="mx-auto text-left"> 
            <h1 className="font-bold mb-2 text-5xl text-red uppercase">Individual Music Therapy</h1>
            <h3 className="font-bold mb-4 text-3xl text-green lg:text-4xl pt-10">Tailored sessions to meet specific needs and NDIS plan goals</h3>
            <p className="mb-6 roboto text-base">
                Melodica Music Therapy offers tailored sessions to meet the specific needs of everyone and reflects the individual goals that people have outlined in their NDIS plans. Music has long been known for its positive effects on mental health and wellbeing. Music Therapists use a person-centred approach and provide a creative outlet for people, particularly those with diverse communication styles and preferences.
            </p>
            <p className="mb-6 roboto text-base">
                Music Therapy utilises evidence-based approaches to develop targeted goals across various areas including communication, emotional regulation, fine and gross motor skills, social skills, attention, engagement and sensory regulation. For people who experience anxiety and stress, music therapy can be a great way to improve self-esteem through participating in an activity where they can demonstrate their strengths and abilities musically.
            </p>
            <p className="mb-6 roboto text-base">
                With its many benefits, Music Therapy is a valuable addition to any comprehensive care plan which can be funded under capacity building supports in your NDIS plan.
            </p>
            </div>
        </div>             
      </section>
      <Contact></Contact>            
      <Footer></Footer>      
    </>
  );
}

export default ServicesIndividualMusicTherapy;