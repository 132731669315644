import React, { useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SEO from '../components/SEO';
import ogImage from '../assets/img/og-image-home.png';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Temporarily disable form submission while we fix email issues
    setSubmitStatus('maintenance');
    
    // Commented out actual submission logic
    /*
    setIsSubmitting(true);
    setSubmitStatus(null);
    try {
      console.log('Submitting form:', formData);
      const response = await fetch('https://api.melodica.com.au/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', phoneNumber: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
    setIsSubmitting(false);
    */
  };

  return (
    <>
      <SEO 
        title="Melodica Music Therapy | Contact | Music Therapy Brisbane"
        description="Trauma Informed Music Therapy and Training for bigs, littles and in-betweens in Brisbane, Australia."
        keywords="music therapy, trauma informed, Brisbane, children, development"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au"
      />
      <Header></Header>
      {/* HERO CONTACT */}
      <section className="hero-contact pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
          <div className="mx-auto text-center max-w-[1200px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">Contact</h2>
            <h1 className="font-bold mb-3 text-4xl text-white uppercase lg:mb-6 lg:text-6xl">Melodica Music Therapy</h1>
          </div>
        </div>
      </section>
      <section className="py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
          <div className="gap-y-6 grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3"> 
            <div className="lg:col-start-1 lg:pr-10 lg:row-span-3 lg:row-start-1 xl:pr-20"> 
              <div className="mx-auto md:max-w-xl lg:max-w-full">
                <div className="bg-[#009d9a] border-8 border-solid border-white p-6 rounded-2xl shadow-2xl lg:p-10"> 
                  {/* <h2 className="font-bold mb-3 text-2xl text-white uppercase lg:text-4xl">Get a Free Support</h2>  */}
                  {submitStatus === 'success' && (
                    <div className="mb-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
                      Your message has been sent successfully!
                    </div>
                  )}
                  {submitStatus === 'error' && (
                    <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                      There was an error sending your message. Please try again.
                    </div>
                  )}
                  {submitStatus === 'maintenance' && (
                    <div className="mb-4 p-2 bg-yellow-100 border border-yellow-400 text-yellow-800 rounded">
                      Contact form is temporarily unavailable. Please email info@melodica.com.au or call 0452 391 605.
                    </div>
                  )}
                  <div className="mb-4 p-3 bg-yellow-100 border border-yellow-400 text-yellow-800 rounded font-medium">
                    Our contact form is temporarily under maintenance. Please email us directly at info@melodica.com.au or call 0452 391 605.
                  </div>
                  <form onSubmit={handleSubmit}> 
                    <div className="mb-4"> 
                      <input 
                        className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full opacity-50" 
                        placeholder="Name" 
                        required={true}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        disabled={true}
                      /> 
                    </div>                                     
                    <div className="mb-4"> 
                      <input 
                        className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full opacity-50" 
                        placeholder="Email" 
                        type="email" 
                        required={true}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={true}
                      /> 
                    </div>
                    <div className="mb-4"> 
                      <input 
                        className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full opacity-50" 
                        placeholder="Phone" 
                        type="text" 
                        required={true}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        disabled={true}
                      /> 
                    </div>                                     
                    <div className="mb-4"> 
                      <textarea 
                        rows={6} 
                        className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full opacity-50" 
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        disabled={true}
                      ></textarea> 
                    </div>
                    <div className="grid mb-6">
                      <button 
                        type="button"
                        className="btn btn-shadow bg-gray-400 text-gray-700 flex font-bold items-center justify-center px-6 py-3 text-xl uppercase lg:px-10 lg:py-4"
                        disabled={true}
                      >
                        Temporarily Disabled
                      </button> 
                    </div>                                     
                  </form>                                 
                </div>
              </div>
            </div>                     
            <div className="row-start-1 lg:pt-10"> 
              <h2 className="font-bold mb-2 roboto text-2xl text-center text-red uppercase lg:text-left">CONTACT</h2>
              <h3 className="font-bold mb-1 text-3xl text-center text-green uppercase lg:text-5xl lg:text-left">Send a Message</h3>
            </div>
            <div className="lg:row-span-2"> 
              <div className="flex items-center">
                <div className="mr-2 lg:mr-4">
                  <span className="fa-2x fa-stack"><i className="fa-circle fa-stack-2x fas text-[#631a86]" /><i className="fa-inverse fa-phone-alt fa-stack-1x fa-xs fas" /></span>
                </div>
                <div className="flex-1 flex-grow">
                  <h5 className="font-bold text-[#631a86] text-base lg:text-2xl">0452 391 605</h5>
                </div>
              </div>
              <div className="flex items-center mt-8">
                <div className="mr-2 lg:mr-4">
                  <span className="fa-2x fa-stack"><i className="fa-circle fa-stack-2x fas text-[#631a86]" /><i className="fa-inverse fa-map-marker-alt fa-stack-1x fa-xs fas" /></span>
                </div>
                <div className="flex-1 flex-grow">
                  <h5 className="font-bold text-[#631a86] text-base lg:text-2xl">Brisbane, QLD, Australia, 4030</h5>
                </div>
              </div>
              <div className="flex items-center mt-8">
                <div className="mr-2 lg:mr-4">
                  <span className="fa-2x fa-stack"><i className="fa-circle fa-stack-2x fas text-[#631a86]" /><i className="fa-envelope fa-inverse fa-stack-1x fa-xs fas" /></span>
                </div>
                <div className="flex-1 flex-grow">
                  <h5 className="font-bold text-[#631a86] text-base lg:text-2xl">info@melodica.com.au</h5>
                </div>
              </div>                         
            </div>                     
          </div>                 
        </div>             
      </section>            
      <Footer></Footer>      
    </>
  );
}

export default Contact;