import { NavLink, Outlet, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { useState } from "react";

function Header() {
  const location = useLocation();
  const activeKey = location.pathname === '/' ? '/home' : location.pathname;
  const [show, setShow] = useState(false);
  const navLinkStyles = ({ isActive }) => {
    return isActive ? "font-bold p-2 text-yellow-400 uppercase hover:text-yellow-400 lg:text-base xl:px-4 xl:text-lg" : "font-bold p-2 text-black uppercase hover:text-yellow-400 lg:text-base xl:px-4 xl:text-lg";
  }
  return (
      <>
      <header> 
        <section className="bg-purple hidden py-3 lg:block"> 
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center justify-between"> 
              <div className="flex">
                <a href="/" className="font-bold group inline-flex items-center text-white"> <i className="fa-map-marker-alt fas mr-2" /> <span className="group-hover:text-yellow-300 md:text-xs lg:text-base">Brisbane, QLD, Australia, Queensland</span> </a>
              </div>
              <div className="flex">
                <a href="tel:+61452391605" className="font-bold group inline-flex items-center text-white"> <i className="fa-phone-alt fas mr-2" /> <span className="group-hover:text-yellow-300 md:text-xs lg:text-base">0452 391 605</span> </a>
                <a href="mailto:info@melodica.com.au" className="font-bold group inline-flex items-center ml-2 text-white lg:ml-8"> <i className="fa-envelope fas mr-2" /> <span className="group-hover:text-yellow-300 md:text-xs lg:text-base">info@melodica.com.au</span> </a>
                <div className="flex ml-2 lg:ml-8">
                  <a href="https://www.instagram.com/melodica_music_therapy" target="_blank" className="group inline-flex items-center ml-2 text-white"> <i className="fa-instagram fab text-lg group-hover:text-yellow-400" /></a>
                  <a href="https://www.facebook.com/p/Melodica-Music-Therapy-100063693664516/" target="_blank" className="font-bold group inline-flex items-center ml-3 text-lg text-white"> <i className="fa-facebook-square fab group-hover:text-yellow-400 text-lg" /></a>
                </div>
              </div>
            </div>
          </div>                 
        </section>
        <nav className="flex flex-wrap items-center py-4"> 
          <div className="container mx-auto px-4 w-full">
            <div className="flex flex-wrap items-center justify-between" data-name="nav-menu"> 
              <div className="flex">
                <button className="lg:hidden mr-4" onClick={() => setShow(!show)}>
                  <svg width={30} height={23} onClick={() => setShow(!show)} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1_341)">
                      <path d="M30 0H0V4.6H30V0Z" fill="black" />
                      <path d="M30 9.2H0V13.8H30V9.2Z" fill="black" />
                      <path d="M30 18.4H0V23H30V18.4Z" fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_341">
                        <rect width={30} height={23} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <a href="/" className="inline-block mr-2 navbar-brand"> <img src={logo} width={254} height={75} className="h-auto w-40 md:w-48 2xl:w-64" /></a>
              </div>
              <a href="/contact" className="btn btn-yellow font-bold inline-block px-3 py-1 roboto rounded text-sm md:px-6 lg:hidden">CONTACT</a>
              <div id="navcol-1" className={`navbar-collapse basis-full bg-white fixed flex-col flex-grow h-full ${show ? '' : 'hidden'} left-0 px-3 py-4 top-0 w-full z-10 lg:basis-auto lg:bg-transparent lg:flex lg:h-auto lg:p-0 lg:static lg:w-auto lg:z-0`}>
                <div className="flex justify-end lg:hidden"> 
                  <button className="p-2 xl:px-4" type="button" onClick={() => setShow(!show)}>
                    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                      <g clipPath="url(#clip0_1_388)"> 
                        <path d="M24.7443 21.2027L3.53113 -0.0105438L-0.00440592 3.52499L21.2088 24.7382L24.7443 21.2027Z" fill="black" /> 
                        <path d="M21.2027 -0.00437129L-0.010498 21.2088L3.52504 24.7444L24.7382 3.53116L21.2027 -0.00437129Z" fill="black" /> 
                      </g>                                         
                      <defs> 
                        <clipPath id="clip0_1_388"> 
                          <rect width="24.75" height="24.75" fill="black" /> 
                        </clipPath>                                             
                      </defs>                                         
                    </svg>                                     
                  </button>                                 
                </div>
                <nav className="flex flex-col lg:flex-row md:mx-auto">
                  <NavLink to="/" className={navLinkStyles}>Home</NavLink>
                  <NavLink to="/about" className={navLinkStyles}>About</NavLink>
                  <NavLink to="/services" className={navLinkStyles}>Services</NavLink>
                  <NavLink to="/uplift" className={navLinkStyles}>Uplift</NavLink>
                  <NavLink to="/courses" className={navLinkStyles}>Courses</NavLink>
                  <NavLink to="/carrm" className={navLinkStyles}>CARRM</NavLink>
                  {/* <NavLink to="/gallery" className={navLinkStyles}>Gallery</NavLink>
                  <NavLink to="/news" className={navLinkStyles}>News</NavLink> */}
                </nav>
                <Outlet />
              </div>
              <a href="/contact" className="btn btn-yellow font-bold hidden px-6 py-2 roboto rounded text-sm hover:bg-yellow-500 lg:inline-block">CONTACT</a> 
            </div>                     
          </div>                 
        </nav>             
      </header>        
      </>
  );
}
  
export default Header;